
import AvatarImage from "@/components/AvatarImage.vue";
import UserStatusTemplate from "@/components/UserStatusTemplate.vue";
import { ServerRolesModule } from "@/store/modules/serverRoles";
import ServerMember from "@/interfaces/ServerMember";
import User from "@/interfaces/User";
import ServerRole from "@/interfaces/ServerRole";
import { PopoutsModule } from "@/store/modules/popouts";
import { ServerMembersModule } from "@/store/modules/serverMembers";
import { permissions } from "@/constants/rolePermissions";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { UsersModule } from "@/store/modules/users";
export default defineComponent({
  name: "RightDrawer",
  components: { AvatarImage, UserStatusTemplate },
  props: {
    serverMember: {
      type: Object as PropType<ServerMember>,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    roles(): any {
      const roles = ServerRolesModule.bulkRolesById(
        this.serverMember.server_id,
        this.serverMember.roleIdArr
      );
      console.log(roles, this.serverMember);
      return roles;
    },
    member(): any {
      return UsersModule.users[this.serverMember.id];
    },
    firstRoleColor(): any {
      if (this.roles[0]) {
        return this.roles[0].color;
      }
      if (this.defaultRole && this.defaultRole.color) {
        return this.defaultRole.color;
      }
      return undefined;
    },
    defaultRole(): any {
      return ServerRolesModule.defaultServerRole(this.serverMember.server_id);
    },
    badge(): any {
      if (this.serverMember.type === "OWNER")
        return ["owner", process.env.VUE_APP_TWEMOJI_LOCATION + "1f451.png"];

      if (this.serverMember.type === "BOT")
        return ["bot", process.env.VUE_APP_TWEMOJI_LOCATION + "1f916.png"];
      const id = this.serverMember.id;
      const serverID = this.serverMember.server_id;
      const memberHasPermission = ServerMembersModule.memberHasPermission;
      if (memberHasPermission(id, serverID, permissions.ADMIN.value)) {
        return ["admin", process.env.VUE_APP_TWEMOJI_LOCATION + "1f6e1.png"];
      }
      return null;
    },
  },
  methods: {
    rightClickEvent(event: MouseEvent) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "UserContextMenu",
        key: this.member.id + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          id: this.member.id,
        },
      });
    },
    showProfile() {
      const rect = (this.$el as HTMLElement).getBoundingClientRect();
      const prevPopout = PopoutsModule.isOpened("profile");
      if (prevPopout && prevPopout.data.member.id !== this.serverMember.id) {
        PopoutsModule.ClosePopout("profile");
      }

      PopoutsModule.ShowPopout({
        id: "profile",
        component: "MiniProfilePopout",
        toggle: true,
        data: {
          x: rect.x,
          y: rect.y,
          member: {
            ...this.serverMember,
            member: this.member,
            roles: this.roles,
          },
        },
      });
    },
  },
});
